<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>User </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateUser">
            <CRow>
              <CCol sm="6">
              <CInput
                v-model="username"
                label="Username"
                placeholder="Input username"
              />
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="nama_lengkap"
                label="Nama Lengkap"
                placeholder="Input nama lengkap"
              />  
              </CCol> 
              <CCol sm="6">
              <CInput
                v-model="email"
                label="Email"
                placeholder="Input email"
              />
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="pass"
                type="password"
                label="Password"
                placeholder="Input password"
              />  
              </CCol> 
              <CCol sm="6">
              <div class="form-group">
              <label> Level</label>
              <select v-model="level" placeholder='Pilih Level' class="form-control" readonly>
                <option value="1">Administrator</option>
                <option value="2">Kontraktor</option>
              </select>
              </div>
              </CCol>      
            </CRow>            

                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/user">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data User gagal diinput.
    </CModal>
   
  </div>
    
  
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      username : "",
      nama_lengkap : "",
      email : "",
      pass : "",
      level : "",
      selected: [], // Must be an array reference!
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.showUser();
  },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    updateUser: function(event){
      // Simple POST request with a JSON body using axios
      const user = { 
                          email: this.email, 
                          nama_lengkap: this.nama_lengkap,
                          pass: this.pass,
                          id_user: this.$route.params.id_user,
                          };
      // alert(JSON.stringify(pekerjaan));
      axios.post("https://sippd.probowsolution.com/public/updateuser", user)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.data=='sukses'){
              this.$router.push('/User');
            }
            else{
              this.myError = true;
            }
            
        })
    },
    showUser: function(event){
      // Simple POST request with a JSON body using axios
      const pekerjaan = { 
                          kode: this.kode, 
                          nama: this.nama,
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get("https://sippd.probowsolution.com/public/edituser/"+username)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            // if(response.data=='sukses'){
              // this.$router.push('/User');
              this.username=response.data.username;
              this.nama_lengkap=response.data.nama_lengkap;
              this.email=response.data.email;
              // this.pass=response.data.pass;
              this.level=response.data.level;

              // alert(response.data);
            // }
            // else{
            //   this.$root.$emit("bv::show::modal", "modalError");
            // }
            
        })
    }
  }
}
</script>